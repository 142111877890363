"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Globals = _interopRequireDefault(require("./es-modules/Core/Globals"));
var _Utilities = _interopRequireDefault(require("./es-modules/Core/Utilities"));
var _AST = _interopRequireDefault(require("./es-modules/Core/Renderer/HTML/AST"));
require("./es-modules/Core/Renderer/HTML/HTMLElement");
require("./es-modules/Core/Renderer/HTML/HTMLRenderer");
require("./es-modules/Core/Axis/Axis");
require("./es-modules/Core/Axis/DateTimeAxis");
require("./es-modules/Core/Axis/LogarithmicAxis");
require("./es-modules/Core/Axis/PlotLineOrBand");
require("./es-modules/Core/Tooltip");
require("./es-modules/Core/Pointer");
require("./es-modules/Core/MSPointer");
require("./es-modules/Core/Legend");
require("./es-modules/Core/Chart/Chart");
require("./es-modules/Extensions/Stacking");
var _Series = _interopRequireDefault(require("./es-modules/Core/Series/Series"));
require("./es-modules/Series/Line/LineSeries");
require("./es-modules/Series/Area/AreaSeries");
require("./es-modules/Series/Column/ColumnSeries");
require("./es-modules/Series/Bar/BarSeries");
require("./es-modules/Series/Scatter/ScatterSeries");
require("./es-modules/Series/Pie/PieSeries");
require("./es-modules/Core/Series/DataLabels");
require("./es-modules/Extensions/OverlappingDataLabels");
require("./es-modules/Core/Responsive");
require("./es-modules/Series/Spline/SplineSeries");
require("./es-modules/Series/Waterfall/WaterfallSeries");
require("./es-modules/Series/Bubble/BubbleSeries");
require("./es-modules/Core/Axis/RadialAxis");
require("./es-modules/Series/AreaRange/AreaRangeSeries");
require("./es-modules/Extensions/Polar");
require("./es-modules/Series/Gauge/GaugeSeries");
require("./es-modules/Series/Histogram/HistogramSeries");
require("./es-modules/Core/Axis/ColorAxis");
require("./es-modules/Mixins/ColorMapSeries");
require("./es-modules/Series/Heatmap/HeatmapSeries");
require("./es-modules/Series/Sankey/SankeySeries");
require("./es-modules/Accessibility/Accessibility");
require("./es-modules/Extensions/PatternFill");
// Utilities
_Globals.default.addEvent = _Utilities.default.addEvent;
_Globals.default.arrayMax = _Utilities.default.arrayMax;
_Globals.default.arrayMin = _Utilities.default.arrayMin;
_Globals.default.attr = _Utilities.default.attr;
_Globals.default.clearTimeout = _Utilities.default.clearTimeout;
_Globals.default.correctFloat = _Utilities.default.correctFloat;
_Globals.default.createElement = _Utilities.default.createElement;
_Globals.default.css = _Utilities.default.css;
_Globals.default.defined = _Utilities.default.defined;
_Globals.default.destroyObjectProperties = _Utilities.default.destroyObjectProperties;
_Globals.default.discardElement = _Utilities.default.discardElement;
_Globals.default.erase = _Utilities.default.erase;
_Globals.default.error = _Utilities.default.error;
_Globals.default.extend = _Utilities.default.extend;
_Globals.default.extendClass = _Utilities.default.extendClass;
_Globals.default.find = _Utilities.default.find;
_Globals.default.fireEvent = _Utilities.default.fireEvent;
_Globals.default.format = _Utilities.default.format;
_Globals.default.getMagnitude = _Utilities.default.getMagnitude;
_Globals.default.getStyle = _Utilities.default.getStyle;
_Globals.default.inArray = _Utilities.default.inArray;
_Globals.default.isArray = _Utilities.default.isArray;
_Globals.default.isClass = _Utilities.default.isClass;
_Globals.default.isDOMElement = _Utilities.default.isDOMElement;
_Globals.default.isFunction = _Utilities.default.isFunction;
_Globals.default.isNumber = _Utilities.default.isNumber;
_Globals.default.isObject = _Utilities.default.isObject;
_Globals.default.isString = _Utilities.default.isString;
_Globals.default.keys = _Utilities.default.keys;
_Globals.default.merge = _Utilities.default.merge;
_Globals.default.normalizeTickInterval = _Utilities.default.normalizeTickInterval;
_Globals.default.numberFormat = _Utilities.default.numberFormat;
_Globals.default.objectEach = _Utilities.default.objectEach;
_Globals.default.offset = _Utilities.default.offset;
_Globals.default.pad = _Utilities.default.pad;
_Globals.default.pick = _Utilities.default.pick;
_Globals.default.pInt = _Utilities.default.pInt;
_Globals.default.relativeLength = _Utilities.default.relativeLength;
_Globals.default.removeEvent = _Utilities.default.removeEvent;
_Globals.default.splat = _Utilities.default.splat;
_Globals.default.stableSort = _Utilities.default.stableSort;
_Globals.default.syncTimeout = _Utilities.default.syncTimeout;
_Globals.default.timeUnits = _Utilities.default.timeUnits;
_Globals.default.uniqueKey = _Utilities.default.uniqueKey;
_Globals.default.useSerialIds = _Utilities.default.useSerialIds;
_Globals.default.wrap = _Utilities.default.wrap;

// Class
_Globals.default.AST = _AST.default;
_Globals.default.Series = _Series.default;

// Additional imports from "highcharts-more"
// https://github.com/highcharts/highcharts/blob/master/ts/masters/highcharts-more.src.js
// Imports/dependencies from 'highchart-more" for gauge series
// AreaRangeSeries must be added before polar.js due to a dependency issue
// Additional imports from "histogram-bellcurve modules
// https://github.com/highcharts/highcharts/blob/master/ts/masters/modules/histogram-bellcurve.src.js
// Additional imports from "heatmap module"
// https://github.com/highcharts/highcharts/blob/master/ts/masters/modules/heatmap.src.js
// Sankey Charts
// Additional imports from "accessibility"
// https://github.com/highcharts/highcharts/blob/master/ts/masters/modules/accessibility.src.js
// Additional imports from "pattern fill module"
// https://github.com/highcharts/highcharts/blob/master/ts/masters/modules/pattern-fill.src.js
var _default = exports.default = _Globals.default;
module.exports = exports.default;